export const styleCard = {
    color: 'black', 
    backgroundColor: '#EAEDED', 
    marginTop: 20
}

export const styleCardHeaderTitle = {
    variant: 'h7'
}

export const styleCardHeader = {
    color: 'white', 
    backgroundColor: '#117A65'
}